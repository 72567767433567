import React from 'react';
import {
    HomePageToolkit,
    HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard } from '@backstage/core-components';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Grid, makeStyles } from '@material-ui/core';
import UrlsIcon from '@material-ui/icons/Link';
import AzureDevopsIcon from './Icons/AzureDevopsIcon';
import LucidIcon from './Icons/LucidIcon';
import MicrosoftIcon from './Icons/MicrosoftIcon';
import ConsulIcon from './Icons/ConsulIcon';
import SlackIcon from './Icons/SlackIcon';
import TeamsIcon from './Icons/TeamsIcon';
import GvIcon from './Icons/GvIcon';
import MonitorIcon from '@material-ui/icons/TrafficOutlined';
import MendIcon from '@material-ui/icons/BugReport';
import ProductBoardIcon from './Icons/ProductBoardIcon';
import FigmaIcon from './Icons/FigmaIcon';
import GrafanaIcon from './Icons/GrafanaIcon';



const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none'
  }
}));

const customLinks: { url: string, label: string, icon: JSX.Element }[] = [
    {
        url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_wiki/wikis/eSmart%20Systems.wiki/3395/gridvision.com-URL-mappings',
        label: 'GV Url Mappings',
        icon: <UrlsIcon />,
    },
    {
        url: 'https://portal.azure.com/',
        label: 'Azure Portal',
        icon: <MicrosoftIcon />,
    },
    {
        url: 'https://condronemagic.slack.com/',
        label: 'Slack',
        icon: <SlackIcon />,
    },
    {
        url: 'https://teams.microsoft.com/',
        label: 'Teams',
        icon: <TeamsIcon />,
    },
    {
        url: 'https://saas-eu.mend.io/',
        label: 'Mend',
        icon: <MendIcon />,
    },
    {
        url: 'https://gridvision.productboard.com/',
        label: 'Product Board',
        icon: <ProductBoardIcon />,
    }

];

const monitorLogLinks: { url: string, label: string, icon: JSX.Element }[] = [
  {
    url: 'https://grid-vision-monitor.azurewebsites.net/',
    label: 'GV Monitor',
    icon: <MonitorIcon />,
  },
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_wiki/wikis/eSmart%20Systems.wiki/2917/Grid-Vision-Cli-Tool',
    label: 'GV Cli Tool',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://grafana.monitoring.dev-we.cluster.esmartapi.com/',
    label: 'Grafana Dev',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.prod-we.cluster.esmartapi.com/',
    label: 'Grafana EU Prod',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.prod-eastus.cluster.esmartapi.com/',
    label: 'Grafanaa US Stage and Prod',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.dev-common.cluster.esmartapi.com/',
    label: 'Grafana Common Dev',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.prod-common.cluster.esmartapi.com/',
    label: 'Grafana Common Prod',
    icon: <GrafanaIcon />,
  }

];

const apiLinks: { url: string, label: string, icon: JSX.Element }[] = [
  {
    url: 'https://client.apiexplorer.dev-we.stamp.esmartapi.com/',
    label: 'API WE Dev',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.apiexplorer.stage-eastus.stamp.esmartapi.com/',
    label: 'API US Stage',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.apiexplorer.prod-we.stamp.esmartapi.com/',
    label: 'API WE Prod',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.apiexplorer.prod-eastus.stamp.esmartapi.com/',
    label: 'API US Prod',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.apiexplorer.dev-common.stamp.esmartapi.com/',
    label: 'API Common Dev',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.apiexplorer.prod-common.stamp.esmartapi.com/',
    label: 'API Common Prod',
    icon: <GvIcon />,
  }

];

const appLinks: { url: string, label: string, icon: JSX.Element }[] = [
  {
    url: 'https://app.gridvision.com',
    label: 'Grid Vision',
    icon: <GvIcon />,
  },
  {
    url: 'https://stage.gridvision.com',
    label: 'Grid Vision [Stage]',
    icon: <GvIcon />,
  },
  {
    url: 'https://dev.gridvision.com',
    label: 'Grid Vision [Dev]',
    icon: <GvIcon />,
  },
  {
    url: 'https://preview.gridvision.com',
    label: 'Grid Vision [Beta Preview]',
    icon: <GvIcon />,
  },
  {
    url: 'https://stage-preview.gridvision.com',
    label: 'Grid Vision [Stage Preview]',
    icon: <GvIcon />,
  },
  {
    url: 'https://dev-preview.gridvision.com',
    label: 'Grid Vision [Dev Preview]',
    icon: <GvIcon />,
  },
  {
    url: 'https://dev-preview.gridvision.com',
    label: 'Grid Vision [Dev Preview]',
    icon: <GvIcon />,
  }

];

const devLinks: { url: string, label: string, icon: JSX.Element }[] = [
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_wiki/wikis/eSmart%20Systems.wiki/18/Developer-Handbook',
    label: 'Wiki',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_sprints/taskboard/GV%20Dev%20team/eSmart%20Systems',
    label: 'Dev Team Sprint',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_backlogs/backlog/Grid%20Vision/Portifolio?showParents=false',
    label: 'GV Backlog',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://lucid.app/documents#/documents?folder_id=project',
    label: 'Lucid',
    icon: <LucidIcon />,
  },
  {
    url: 'https://teams.microsoft.com/',
    label: 'Figma',
    icon: <FigmaIcon />,
  },
  {
    url: 'https://consul.common.service.esmartapi.com/ui/dc1/kv',
    label: 'Consul',
    icon: <ConsulIcon />,
  }

];
export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} justifyContent='center'>
              <HomePageSearchBar
                InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline }}}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={8}>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Monitoring and logging"
                  tools={monitorLogLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="API Explorer"
                  tools={apiLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Grid Vision Clients"
                  tools={appLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Developer Tools"
                  tools={devLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Useful Links"
                  tools={customLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoCard title="Information">
                  {/* placeholder for content */}
                    <div style={{ height: 370 }}>
                                      What will you find here? <br />
                                      <ul>
                                          <li>A catalog of all systems and components used to build Grid Vision</li>
                                          <li>CI/CD pipelines</li>
                                          <li>Pull requests</li>
                                          <li>Tech Documentation</li>
                                          <li>Component dependencies</li>
                                          <li>Available API's</li>
                                      </ul>

                    </div>
                </InfoCard>
              </Grid>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={12} md={12}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};